<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog" v-if="month === current_month">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row class="py-2">
          <v-col cols="12" md="6">
            <date-component v-model="month" req label="Місяць" disabled/>
          </v-col>
          <v-col md="6"></v-col>
          <v-col cols="12" md="6">
            <v-select :items="services"
                      filled label="Послуга (для закриття)"
                      hide-details
                      v-model="service_id_to_close"
                      color="grey"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="service_id_to_close ? '' : 'req-star'"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="Сума (закриття)"
                          v-model.number="sum_to_close"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="sum_to_close ? '' : 'req-star'"
                          color="grey"
                          v-decimal
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select :items="services"
                      filled label="Послуга (для перенесення)"
                      hide-details
                      v-model="service_id_to_swap"
                      color="grey"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="service_id_to_swap ? '' : 'req-star'"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="Сума (перенесення)"
                          v-model.number="sum_to_swap"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="sum_to_swap ? '' : 'req-star'"
                          color="grey"
                          v-decimal
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
                type="text" hide-details filled label="Коментар"
                v-model="comment"
                color="grey"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="updateService" :loading="getModalLoading"
             :disabled="month !== current_month"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {ALERT_SHOW} from "@/store/actions/alert";
import {GET_ALL_WITH_BALANCE} from "@/store/actions/flat_service";
import {SELECT_SERVICES} from "@/store/actions/services";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";

import closeSwapDebtAPI from "@/utils/axios/close_swap_debt";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const modalDeleteId = 'close_swap_debt_by_flat_modal_delete'

export default {
  name: "HWP_Modal_CloseSwapDebtByFlat",
  mixins: [ModalComponentMixin],
  data() {
    return {
      person_hash: this.item.person_hash || undefined,
      close_id: this.item.close_id || null,
      swap_id: this.item.swap_id || null,
      service_id_to_close: this.item.service_id_to_close || null,
      service_id_to_swap: this.item.service_id_to_swap || null,
      sum_to_close: this.item.sum_to_close || 0,
      sum_to_swap: this.item.sum_to_swap || 0,
      month: this.item.month,
      comment: this.item.comment || '',
    }
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithoutAll',
      current_month: 'getCurrentMonth',
    }),
  },
  methods: {
    ...mapActions({
      fetchServices: SELECT_SERVICES,
      fetchFlatServices: GET_ALL_WITH_BALANCE
    }),
    closeModal(payload=null) {
      this.$emit('closeModal', payload)
      this.itemId = this.item.id
      this.person_hash = this.item.person_hash || null
      this.close_id = this.item.close_id || null
      this.swap_id = this.item.swap_id || null
      this.service_id_to_close = this.item.service_id_to_close || null
      this.service_id_to_swap = this.item.service_id_to_swap || null
      this.sum_to_close = this.item.sum_to_close || 0
      this.sum_to_swap = this.item.sum_to_swap || 0
      this.month = this.item.month
      this.comment = this.item.comment

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення одноразового нарахування: ${this.getServiceName()}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    updateService() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Послуга (для закриття), послуга (для перенесення) та суми мають бути заповнені',
          color: 'error lighten-1'
        })
        return undefined
      }

      const payload = {
        person_hash: this.person_hash,
        service_id_to_close: this.service_id_to_close,
        service_id_to_swap: this.service_id_to_swap,
        month: this.month,
        sum_to_close: this.sum_to_close,
        sum_to_swap: this.sum_to_swap,
        comment: this.comment,
      }

      this.$store.commit(START_MODAL_LOADING)

      if (this.isNew) {
        closeSwapDebtAPI.create_by_flat(payload)
          .then(response => response.data)
          .then(data => {
            if (data) {
              this.closeModal({ operation: 'fetch' })
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
          .finally(() => {
            this.$store.commit(STOP_MODAL_LOADING)
          })
      } else {
        payload['close_id'] = this.close_id
        payload['swap_id'] = this.swap_id
        closeSwapDebtAPI.update_by_flat(payload)
          .then(response => response.data)
          .then(data => {
            if (data) {
              this.closeModal({ operation: 'fetch' })
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
          .finally(() => {
            this.$store.commit(STOP_MODAL_LOADING)
          })
      }
    },
    getServiceName() {
      if (this.service_id_to_close) {
        const ind = this.services.find(item => item.value === this.service_id)
        if (ind) {
          return ind.text
        }
      }
      return ''
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.close_id = payload.close_id
              this.swap_id = payload.swap_id
              this.person_hash = payload.person_hash
              this.service_id_to_close = payload.service_id_to_close
              this.service_id_to_swap = payload.service_id_to_swap
              this.month = payload.month
              this.sum_to_close = payload.sum_to_close
              this.sum_to_swap = payload.sum_to_swap
              this.comment = payload.comment
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.commit(START_MODAL_LOADING)
                  closeSwapDebtAPI.remove_by_flat({close_id: this.close_id, swap_id: this.swap_id})
                    .then(response => response.data)
                    .then(data => {
                      if (data) {
                        this.$store.commit(STOP_MODAL_LOADING)
                        this.closeModal({ operation: 'fetch' })
                      }
                    })
                    .catch(err => {
                      const error = err.response.data.detail;
                      this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    })
                    .finally(() => {
                      this.$store.commit(STOP_MODAL_LOADING)
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  created() {
    this.fetchServices()
  }
}
</script>
